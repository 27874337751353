import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import axios from "axios";
import * as constantes from "../Screens/Parts/AlveoleConstants";
import Cookies from "js-cookie";

// Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Messaging service
const messaging = getMessaging(app);

// Function to request permission and get token
export const requestForToken = async (setTokenFound) => {
    return getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY})
        .then((currentToken) => {
            if (currentToken) {
                //console.log('current token for client: ', currentToken);
                setTokenFound(true);
                axios.post(`${constantes.zUrlBack}/save-firebase-token`,
                    {
                        firebaseToken: currentToken,
                        user: Cookies.get('idUser')
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })
                    .then(response => {
                        //console.log('Token saved : '+ currentToken);
                    })
                    .catch(error => {
                        console.error('Error saving token:', error);
                    });
            } else {
                console.log('No registration token available. Request permission to generate one.');
                setTokenFound(false);
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            setTokenFound(false)
        });
}
// Function to load service worker
export const loadServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((registration) => {
                //console.log('Registration successful, scope is:', registration.scope);
            }).catch((err) => {
            console.log('Service worker registration failed, error:', err);
        });
    }
};
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
export {messaging};
