import React, {useEffect, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from '@mui/material/Grid2'
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import axios from "axios";
import toast from 'react-hot-toast';

import CristalLogoLight from './../images/logo-cristalhub-light.svg';
import CristalLogoDark from './../images/logo-cristalhub-dark.svg';
import IconBackDark from './../images/icon-arrow-left-back-dark.svg';
import IconBackLight from './../images/icon-arrow-left-back-light.svg';
import * as constantes from './Parts/AlveoleConstants';
import {
    CristalBackBleuButton,
    CristalBlueButton,
    CristalLogo,
    CristalPinkButton, CristalWhiteButton,
    StyledBadge, useStyles
} from "./Parts/ComponentLayout";
import NotificationMenu from "./Parts/NotificationMenu";
import NotificationFirebase from "../firebase/NotificationFirebase";
import {onMessageListener, requestForToken} from "../firebase/firebaseConfig";


const Layout = ({children}) => {
    const [notification, setNotification] = useState({title: '', body: ''});
    const [isTokenFound, setTokenFound] = useState(false);

    const classes = useStyles();
    const [bLoading, setBLoading] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [calendars, setCalendars] = React.useState([]);
    const [documents, setDocuments] = React.useState([]);
    const [bShowNotifications, setBShowNotifications] = React.useState(false); //fenêtre d'affichage notifications
    const [bConsents, setBConsents] = React.useState(false);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const moment = require('moment');
    require('moment/locale/fr');

    useEffect(() => {
        const timer = setTimeout(() => {
            setBLoading(false);
        }, 5000); //  milliseconds
        return () => clearTimeout(timer); // Annule le timer si le composant est démonté avant le délai
    }, []);

    useEffect(() => {
        let cookieTheme = Cookies.get('theme');
        requestPermissionForNotification();
        onMessageListener()
            .then((payload) => {
                setNotification({title: payload.notification.title, body: payload.notification.body});
                toast.custom( // foreground message
                    (t) => (
                        <div
                            style={{
                                fontSize: '14px',
                                background: cookieTheme === "light" ? 'var(--background-global-03-light)' : 'var(--background-global-03-dark)',
                                padding: '10px',
                                borderRadius: '4px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <h3>{payload.notification.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: payload.notification.body.replace(/\n/g, '<br>')}}/>
                        </div>
                    ),
                    {duration: 15000,}
                );
                //console.log(payload);
            })
            .catch(err => console.log('failed: ', err));
    }, []);

    const requestPermissionForNotification = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                await requestForToken(setTokenFound);
            } else {
                console.log("Unable to get permission to notify.");
            }
        } catch (error) {
            console.error('Error during permission request', error);
        }
    };

    const fOpenNotifications = () => {
        setBShowNotifications(true);
    }
    const fCloseNotifications = () => {
        setBShowNotifications(false);
    }
    const fRemoveNotifications = (value) => {
        // calendars = calendars.filter((calendar) => calendar['@id'] === response.data['notification']['@id'])
        axios.post(`${constantes.zUrlBackNotifications}/remove-notification`, {
                idUser: Cookies.get('idUser'),
                idNotification: value
            },
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            })
            .then((response) => {
                console.log(response.data['notification']['externalId']) // élément supprimé
                if (response.data['notification']['type'] === 'calendar') {
                    setCalendars(calendars.filter((calendar) => calendar['externalId'] !== value));
                } else {
                    setDocuments(documents.filter((doc) => doc['externalId'] !== value));
                }
            })
            .catch((error) => {
                console.log(error)
            })
        ;
    }

    // Recuperation des notifications
    const fetchNotifications = () => {
        setBLoading(true);
        axios.post(`${constantes.zUrlBackNotifications}/notifications`,
            {
                token: Cookies.get('token'),
                societe: Cookies.get('socId'),
                compte: Cookies.get('accountId'),
                idUser: Cookies.get('idUser')
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                if (response.status === 200) {
                    // Récupération Datas calendar
                    const calendarList = response.data['calendarList'].map(event => {
                        event.dateEvenement = moment.utc(event.dateEvenement, 'DD-MM-YYYY hh:mm').local().format('D MMMM YYYY HH:mm');
                        return event;
                    });
                    setCalendars(calendarList);
                    // Récupération Datas documents
                    setDocuments(response.data['zeenDocsList']);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setBLoading(false);
            });
    };

    useEffect(() => {
        let accountAcces;
        axios.post(`${constantes.zUrlBackNotifications}/customer-check`,
            {
                token: Cookies.get('token'),
                accountName: Cookies.get('compteParamNom')
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(response => {
                if (response.status === 200) {
                    accountAcces = response.data['accountAcces'];
                }

            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                if (accountAcces !== null) {
                    fetchNotifications();
                }
            });

    }, []);

    useEffect(() => {
        axios.post(`${constantes.zUrlBackNotifications}/notifications-consents`,
            {token: Cookies.get('token')}, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                let notificationValue = response.data['consentNotif'];
                setBConsents(notificationValue);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const handleCloseLoading = () => {
        setBLoading(false);
    };
    const handleHome = () => {
        window.location.assign(constantes.zURLhome);
    }
    const handleRetour = () => {
        window.history.back();
        setTimeout(() => {// remettre en haut de la page
            window.scrollTo(0, 0);
        }, 1);

    }
    return (
        <>
            <CssBaseline/>
            <Container maxWidth="xl">
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={bLoading}
                    onClick={handleCloseLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Box sx={{flexGrow: 1}}>
                    <NotificationFirebase/>
                    <Grid
                        container
                        direction="row"

                        justifyContent="space-between"
                        alignItems="center"
                        sx={{margin: '36px 0px 14px'}}
                    >
                        <Grid size={6}>
                            <CristalLogo
                                src={theme.palette.mode === 'light' ? `${CristalLogoLight}` : `${CristalLogoDark}`}
                                loading="lazy" sx={{maxWidth: '100%'}}/>
                        </Grid>

                        {Cookies.get('socId') && Cookies.get('accountId') && (
                            <Grid container
                                  size={6}
                                  direction="row"
                                  flexWrap="nowrap"
                                  justifyContent="flex-end"
                                  alignItems="center">
                                {calendars.length === 0 && documents.length === 0 ? (
                                    <Grid item>
                                        <CristalBlueButton
                                            disableRipple
                                            sx={{
                                                cursor: 'default',
                                                marginRight: '.5rem!important'
                                            }}
                                        >
                                            <img src={require('./../images/icon-notif.svg').default}
                                                 alt="CristalHub"
                                                 className="CristalNotifButton"/>
                                        </CristalBlueButton>
                                    </Grid>
                                ) : (
                                    <Grid item>
                                        <Box>
                                            <StyledBadge theme
                                                         badgeContent=" "
                                                         overlap="circular"
                                                         sx={{
                                                             '& .MuiBadge-badge':
                                                                 {border: theme.palette.mode === 'light' ? '2px solid #E5F9FE' : '2px solid #003350'}
                                                         }}
                                            >
                                                <CristalBlueButton
                                                    disableRipple
                                                    onClick={fOpenNotifications}
                                                    sx={{marginRight: '.5rem!important'}}
                                                >
                                                    <img
                                                        src={require('./../images/icon-notif.svg').default}
                                                        alt="CristalHub"
                                                        className="CristalNotifButton"/>
                                                </CristalBlueButton>
                                            </StyledBadge>
                                        </Box>
                                        <NotificationMenu
                                            anchorEl={anchorEl}
                                            bShowNotifications={bShowNotifications}
                                            fCloseNotifications={fCloseNotifications}
                                            fRemoveNotifications={fRemoveNotifications}
                                            calendars={calendars}
                                            documents={documents}
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    {bConsents === true ? (
                                        <Box>
                                            <StyledBadge
                                                classes={{badge: classes.customBadge}}
                                                badgeContent=" "
                                                overlap="circular"
                                                sx={{
                                                    '& .MuiBadge-badge':
                                                        {border: theme.palette.mode === 'light' ? '2px solid #E5F9FE' : '2px solid #003350'}
                                                }}>
                                                <CristalBlueButton disableRipple>
                                                    <img
                                                        src={require('./../images/icon-settings.svg').default}
                                                        alt="CristalHub"
                                                        className="CristalSettingsButton"/>
                                                </CristalBlueButton>
                                            </StyledBadge>
                                        </Box>
                                    ) : (
                                        <CristalBlueButton disableRipple>
                                            <img
                                                src={require('./../images/icon-settings.svg').default}
                                                alt="CristalHub"
                                                className="CristalSettingsButton"/>
                                        </CristalBlueButton>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container className="IconsNavigationContainer">
                        <Grid item md={12} xs={12}>
                            <Grid
                                container
                                direction="row">
                                <Grid item>
                                    <CristalPinkButton
                                        onClick={handleHome}
                                        disableRipple>
                                        <img src={require('./../images/icon-home-blc.svg').default}
                                             alt="CristalHub"
                                             className="CristalIconButton"/>
                                    </CristalPinkButton>
                                </Grid>
                                <Grid item>
                                    <CristalBackBleuButton
                                        onClick={handleRetour}
                                        disableRipple>
                                        <img
                                            src={theme.palette.mode === 'light' ? `${IconBackLight}` : `${IconBackDark}`}
                                            alt="CristalHub"
                                            className="CristalIconButton"/>
                                    </CristalBackBleuButton>
                                </Grid>
                                <Grid item>
                                    <CristalWhiteButton
                                        disableRipple
                                        sx={{marginLeft: '15px'}}>
                                        <img src={require('./../images/icon-settings-dark.svg').default}
                                             alt="CristalHub"
                                             className="CristalIconButton"
                                        />
                                    </CristalWhiteButton>
                                </Grid>
                                <Grid item>
                                    <Grid container
                                          direction="column"
                                          justifyContent="center"
                                          alignItems="flex-start"
                                          sx={{marginLeft: '11px', marginTop: '5px'}}>
                                        <Grid item>
                                            <Typography>
                                                Vous êtes sur...
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                <span> {constantes.vousetessur} </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*  Contenu spécifique de chaque composant */}
                    {React.Children.map(children, child =>
                        React.cloneElement(child)
                    )}
                </Box>
            </Container>
        </>
    );
}
export default Layout;
